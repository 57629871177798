import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text
} from "@chakra-ui/react";
import { UserInfo } from "@shared/domain.models";
import { useQuery } from "@tanstack/react-query";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoPersonAddSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

import { AdminItem } from "~/components/AdminItem";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { ThemeColors } from "~/styles/theme";
import { downloadAdminExcel } from "~/utils/downloadAdminExcel";

const AdminListPage = () => {
  const { id } = useParams<{ id: string }>();
  const { useGetOrgById } = useDbOrgs();
  const navigate = useNavigate();

  const { data: org, isLoading, isError } = useGetOrgById(Number(id));

  // Fetch fake users from JSON
  const {
    data: fetchedAdmins,
    isLoading: adminsLoading,
    isError: adminsError
  } = useQuery({
    queryKey: ["fake_users"],
    queryFn: async () => {
      const res = await fetch("/assets/fake_users.json");
      if (!res.ok) throw new Error("Network response was not ok");
      const data = await res.json();
      return data.map((admin: UserInfo) => ({
        ...admin,
        createdAt: new Date(admin.createdAt)
      }));
    }
  });

  // Merge the fetched admins with the org (if org exists)
  const mergedOrg = org ? { ...org, admins: fetchedAdmins } : null;
  return (
    <Flex direction="column" align="start">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
        width="100%"
        mb={50}
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <HStack>
          <IconButton
            aria-label="Back"
            onClick={() => {
              if (window.history.length > 2) {
                navigate(-1);
              } else {
                navigate("/orgs");
              }
            }}
            variant="ghost"
            color={ThemeColors.brand.primary}
          >
            <IoIosArrowRoundBack size={50} />
          </IconButton>

          <Heading size={{ base: "lg", md: "xl" }}>Manage Admins: "{org?.name}"</Heading>
        </HStack>
        <HStack flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Button variant="ghost" onClick={() => navigate(`/orgs/${id}`)}>
            Manage Organization
          </Button>
          <Popover>
            <PopoverTrigger>
              <Button variant="outline" leftIcon={<IoPersonAddSharp />}>
                Invite Admin
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <HStack>
                    <Input placeholder="Admin Email" />
                    <Button variant="outline">Invite</Button>
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button
            variant="outline"
            leftIcon={<BsArrowDownSquareFill />}
            onClick={() => downloadAdminExcel(fetchedAdmins, "Admins.xlsx")}
          >
            Export
          </Button>
          s
        </HStack>
      </HStack>
      <Divider my={4} />
      <Box borderRadius={10} border="1px solid" borderColor="gray.200" p={4} w="100%">
        <List width="100%">
          <ListItem key="header" p={4}>
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr) 40px"
              }}
              gap={4}
              alignItems="center"
            >
              <Text color="gray.500">Admin Name</Text>
              <Text color="gray.500">Admin Email</Text>
              <Text color="gray.500">Status</Text>
              <Text color="gray.500">Date Added</Text>
            </Grid>
          </ListItem>
          {mergedOrg?.admins?.map((admin: UserInfo) => <AdminItem admin={admin} key={admin.id} />)}
          {(mergedOrg?.admins?.length === 0 || !mergedOrg?.admins) && <Text>No admins found.</Text>}
        </List>
      </Box>
    </Flex>
  );
};
export default AdminListPage;
