import { UserInfo, UserRole } from "@shared/domain.models";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";

import { queryClient } from "~/App";

export function useDbUsers() {
  const useGetAllUsers = (): UseQueryResult<UserInfo[]> =>
    useQuery<UserInfo[]>({
      queryKey: ["users"],
      queryFn: async () => {
        const response = await fetch("/api/users");
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      }
    });
  const useSetUserRole = () => {
    const mutation = useMutation<UserInfo, Error, { userId: number; role: UserRole }>({
      mutationFn: async ({ userId, role }) => {
        const response = await fetch(`/api/users/${userId}/set-role`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ role })
        });
        if (!response.ok) {
          throw new Error("Failed to set user role");
        }
        const updatedUser = await response.json();
        queryClient.invalidateQueries({ queryKey: ["users"] });
        return updatedUser;
      }
    });
    return (userId: number, role: UserRole) => mutation.mutateAsync({ userId, role });
  };
  return { useGetAllUsers, useSetUserRole };
}
