import { Divider, Flex, Heading, HStack, IconButton } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { OrgForm } from "./OrgForm";

import { useDbOrgs } from "~/hooks/useDbOrgs";
import { ThemeColors } from "~/styles/theme";

export const OrgCreate = () => {
  const { useCreateOrg } = useDbOrgs();
  const navigate = useNavigate();

  const createOrgMutation = useCreateOrg();

  const handleCreate = (data: Organization) => {
    const newOrg: Organization = {
      ...data,
      id: undefined
    };
    //get domains as list
    if (typeof data.domains === "string") {
      const arrayDomains = (data.domains as string).split(",").map((domain: string) => domain.trim());
      newOrg.domains = arrayDomains;
    }
    // create empty array for admins until we handle that logic
    newOrg.admins = [];
    console.log(newOrg);
    createOrgMutation.mutate(newOrg, {
      onSuccess: () => {
        console.log("Organization created successfully");
        navigate("/orgs");
      },
      onError: (error) => {
        console.error("Error creating organization:", error);
      }
    });
  };

  return (
    <Flex direction="column" align="start">
      <HStack alignContent="center" mb={10}>
        <IconButton
          aria-label="Back to Organizations"
          onClick={() => navigate("/orgs")}
          variant="ghost"
          color={ThemeColors.brand.primary}
        >
          <IoIosArrowRoundBack size={50} />
        </IconButton>
        <Heading color={ThemeColors.brand.primary} as="h1">
          Add Organization
        </Heading>
      </HStack>
      <Divider mb={4} />
      <OrgForm onSubmit={handleCreate} onCancel={() => navigate("/orgs")} />
    </Flex>
  );
};
