import { UserInfo } from "@shared/domain.models";
import ExcelJs from "exceljs";

export async function downloadAdminExcel(admins: UserInfo[], filename: string) {
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet("Admins");
  worksheet.columns = [
    { header: "ID", key: "id", width: 10 },
    { header: "First Name", key: "firstName", width: 20 },
    { header: "Last Name", key: "lastName", width: 20 },
    { header: "Email Address", key: "emailAddress", width: 32 },
    { header: "Is Super Admin", key: "isSuperAdmin", width: 20 },
    { header: "Is Org Admin", key: "isOrgAdmin", width: 20 },
    { header: "Org ID", key: "orgId", width: 20 },
    { header: "Org Name", key: "orgName", width: 20 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Is Active", key: "isActive", width: 20 }
  ];
  admins.forEach((admin) => {
    worksheet.addRow({
      id: admin.id,
      firstName: admin.firstName,
      lastName: admin.lastName,
      emailAddress: admin.emailAddress,
      isSuperAdmin: admin.isSuperAdmin ? "true" : "false",
      isOrgAdmin: admin.isOrgAdmin ? "true" : "false",
      orgId: admin.orgId,
      orgName: admin.orgName,
      createdAt: new Date(admin.createdAt).toISOString().split("T")[0],
      isActive: admin.isActive ? "true" : "false"
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(link.href);
}
