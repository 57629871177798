import { useState, useMemo } from "react";

import {
  Flex,
  Heading,
  List,
  ListItem,
  Grid,
  Spinner,
  Text,
  Button,
  HStack,
  Divider,
  Box,
  Input
} from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { AvDatePicker } from "~/components/AvDatePicker";
import { ButtonMenu } from "~/components/ButtonMenu";
import { OrgItem } from "~/components/OrgItem";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { downloadOrgExcel } from "~/utils/downloadOrgExcel";

const filterByNameOrDomain = (org: Organization, filterText: string) => {
  // If no filter text, show all
  if (!filterText.trim()) return true;
  const lowerFilter = filterText.toLowerCase();
  return (
    org.name.toLowerCase().includes(lowerFilter) ||
    org.domains.some((domain: string) => domain.toLowerCase().includes(lowerFilter))
  );
};

const filterByStatus = (org: Organization, status: string) => {
  if (status === "All") return true;
  if (status === "Active") return org.isActive === true;
  if (status === "Inactive") return org.isActive === false;
  return true;
};

const filterByExpiration = (org: Organization, date: Date | null) => {
  // If no date chosen, do not filter by expiration
  if (!date) return true;
  if (!org.serviceEndDate) return false;
  const orgEnd = new Date(org.serviceEndDate);
  return orgEnd <= date;
};

const OrgListPage = () => {
  const { useAllOrgs } = useDbOrgs();
  const { data: orgs, isLoading, error } = useAllOrgs();

  const [statusFilter, setStatusFilter] = useState<string>("All");
  const [orgFilter, setOrgFilter] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<Date | null>(null);

  const navigate = useNavigate();

  const filteredOrgs = useMemo(() => {
    if (!orgs) return [];
    return orgs.filter((org) => {
      return (
        filterByNameOrDomain(org, orgFilter) && filterByStatus(org, statusFilter) && filterByExpiration(org, dateFilter)
      );
    });
  }, [orgs, orgFilter, statusFilter, dateFilter]);

  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading Organizations...</Text>
      </Flex>
    );
  }

  if (error instanceof Error) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text color="red.500">Error: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="start" overflowX="auto" w="100%" gap={2}>
      <HStack
        justifyContent={{ base: "flex-start", md: "space-between" }}
        width="100%"
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Heading size={{ base: "lg", md: "xl" }} mb={4}>
          Organizations
        </Heading>
        <HStack spacing={2} flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Button variant="outline" gap={2} display="flex" alignItems="center" onClick={() => navigate("/orgs/new")}>
            <FaPlus />
            Add Organization
          </Button>
          <Button
            variant="outline"
            gap={2}
            border="1px solid"
            display="flex"
            alignItems="center"
            onClick={() => downloadOrgExcel(filteredOrgs, "organizations.xlsx")}
          >
            <BsArrowDownSquareFill />
            Export
          </Button>
        </HStack>
      </HStack>
      <HStack alignItems="center" spacing={{ base: 2, md: 4 }} flexWrap={{ base: "wrap", md: "nowrap" }}>
        <ButtonMenu
          value={statusFilter}
          setValue={(value) => {
            setStatusFilter(value);
          }}
          options={["All", "Active", "Inactive"]}
        />
        <Input
          placeholder="Search Organizations..."
          onChange={(e) => {
            setOrgFilter(e.target.value);
          }}
        />
        <HStack w="100%">
          <Text>Expiration: </Text>
          <AvDatePicker
            selectedDate={dateFilter}
            onChange={(date) => {
              setDateFilter(date || null);
            }}
            inputProps={{ size: "md" }}
            isClearable
          />
        </HStack>
      </HStack>
      <Divider my={4} />
      <Box
        borderRadius={10}
        border="1px solid"
        borderColor="gray.200"
        p={{ base: 1, md: 4 }}
        w="100%"
        maxW="100vw"
        overflowX="auto"
      >
        <List width="100%">
          <ListItem key="header" p={4}>
            <Grid
              templateColumns={{
                base: "repeat(3, 1fr)",
                md: "repeat(5, 1fr) 40px"
              }}
              gap={4}
              alignItems="center"
            >
              <Text color="gray.500">Organization Name</Text>
              <Text color="gray.500">Email Domains</Text>
              <Text color="gray.500">Status</Text>
              <Text color="gray.500">Start Date</Text>
              <Text color="gray.500">Expiration Date</Text>
            </Grid>
          </ListItem>
          {filteredOrgs.map((org) => (
            <OrgItem org={org} key={org.id} />
          ))}
          {filteredOrgs.length === 0 && <Text>No organizations found.</Text>}
        </List>
      </Box>
    </Flex>
  );
};

export default OrgListPage;
