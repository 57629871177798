import { Box, Text, Button, Heading, Divider, Flex, HStack, IconButton, Icon } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { FaBoxArchive } from "react-icons/fa6";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";

import { OrgForm } from "./OrgForm";

import { useDbOrgs } from "~/hooks/useDbOrgs";
import { ThemeColors } from "~/styles/theme";

export const OrgEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { useGetOrgById, useUpdateOrg } = useDbOrgs();
  const navigate = useNavigate();

  const { data: org, isLoading, isError } = useGetOrgById(Number(id));
  const updateOrgMutation = useUpdateOrg();

  const handleUpdate = (data: Organization) => {
    if (!org) {
      console.error("Organization data is not available");
      return;
    }
    if (typeof data.domains === "string") {
      data.domains = (data.domains as string).split(",").map((domain: string) => domain.trim());
    }
    const updatedOrg: Organization = {
      ...org,
      name: data.name,
      domains: data.domains
    };

    if (org.id === undefined) {
      console.error("Organization ID is not available");
      return;
    }
    updateOrgMutation.mutate(
      { orgId: org.id as number, updatedOrg },
      {
        onSuccess: () => {
          console.log("Organization updated successfully");
          navigate("/orgs");
        },
        onError: (error) => {
          console.error("Error updating organization:", error);
        }
      }
    );
  };

  const handleDelete = () => {
    if (!org) {
      console.error("Organization data is not available");
      return;
    }
    console.log("Deleting org:", org.name);
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isError || !org) {
    return (
      <Box textAlign="center" mt={10}>
        <Text fontSize="2xl" fontWeight="bold">
          Error
        </Text>
        <Text>The organization with ID {id} could not be found.</Text>
        <Button mt={4} onClick={() => navigate("/orgs")}>
          Back to Organizations
        </Button>
      </Box>
    );
  }

  return (
    <Flex direction="column" align="start">
      <HStack alignItems="center" justifyContent="space-between" w="100%" mb={10}>
        <HStack alignItems="center">
          <IconButton
            aria-label="Back"
            onClick={() => {
              if (window.history.length > 2) {
                navigate(-1);
              } else {
                navigate("/orgs");
              }
            }}
            variant="ghost"
            color={ThemeColors.brand.primary}
          >
            <IoIosArrowRoundBack size={50} />
          </IconButton>

          <Heading color={ThemeColors.brand.primary} as="h1">
            Edit Organization
          </Heading>
        </HStack>
        <Button onClick={handleDelete} variant="outline" aria-label="delete organization" gap={2}>
          <Icon as={FaBoxArchive} />
          Archive
        </Button>
      </HStack>
      <Divider mb={4} />
      <OrgForm defaultValues={org} onSubmit={handleUpdate} onCancel={() => navigate("/orgs")} onDelete={handleDelete} />
    </Flex>
  );
};
