import {
  ListItem,
  Text,
  Grid,
  Popover,
  PopoverTrigger,
  IconButton,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
  Flex
} from "@chakra-ui/react";
import { UserInfo, UserRole } from "@shared/domain.models";
import { BsThreeDots } from "react-icons/bs";

import { useDbUsers } from "~/hooks/useDbUsers";
import { ThemeColors } from "~/styles/theme";
export const AdminItem = ({ admin }: { admin: UserInfo }) => {
  const { useSetUserRole } = useDbUsers();
  const setUserRole = useSetUserRole();
  const handleSetUserRole = () => {
    if (admin.isSuperAdmin) {
      setUserRole(admin.id, UserRole.Admin);
    } else {
      setUserRole(admin.id, UserRole.SuperAdmin);
    }
  };
  return (
    <ListItem key={admin.id} p={4} border={{ base: "1px solid gray", md: "none" }}>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(4, 1fr) 40px"
        }}
        gap={4}
        alignItems="center"
      >
        <Text>
          {admin.firstName} {admin.lastName}
        </Text>
        <Text>{admin.emailAddress}</Text>
        {admin.isActive ? <Text>Active</Text> : <Text color="red.500">Inactive</Text>}
        <Text>{admin.createdAt}</Text>
        <Popover>
          <PopoverTrigger>
            <IconButton aria-label="Options" margin="auto" icon={<BsThreeDots />} variant="plain" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Flex direction="column" alignItems="flex-start">
                  <Button
                    colorScheme={ThemeColors.brand.primary}
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to ${admin.isSuperAdmin ? "remove Super Admin rights" : "grant Super Admin rights"}?`
                        )
                      ) {
                        handleSetUserRole();
                      }
                    }}
                  >
                    {admin.isSuperAdmin ? "Remove Super Admin" : "Make Super Admin"}
                  </Button>
                  <Button colorScheme={ThemeColors.brand.primary} size="sm" variant="ghost">
                    Resend Invite
                  </Button>
                  <Button colorScheme={ThemeColors.brand.primary} size="sm" variant="ghost">
                    <Text color="red.500">Deactivate Admin</Text>
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Grid>
    </ListItem>
  );
};

export default AdminItem;
