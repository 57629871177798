import { Box, Button, Grid, Heading, Image } from "@chakra-ui/react";

const Page404 = () => {
  const handleBackToHome = () => {
    alert("Testing error handling");
    throw new Error("Testing error handling");
  };

  return (
    <Grid gap={4} textAlign="center" p="10">
      <Heading>
        Page <span onClick={handleBackToHome}>not</span> Found
      </Heading>

      <Box maxWidth={[280, 400]} marginX="auto">
        <Image width={400} src="/assets/404 Error-rafiki.svg" />
      </Box>

      <Box>
        <Button onClick={handleBackToHome}>Go to your dashboard</Button>
      </Box>
    </Grid>
  );
};

export default Page404;
