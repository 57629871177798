import { Box, Input, Button, Text, Heading, Flex, Textarea } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AvDatePicker } from "~/components/AvDatePicker";
import { ThemeColors } from "~/styles/theme";

interface OrgFormProps {
  defaultValues?: Organization;
  onSubmit: (data: Organization) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

export const OrgForm = ({ defaultValues, onSubmit, onCancel, onDelete }: OrgFormProps) => {
  const { register, handleSubmit, control } = useForm<Organization>({
    defaultValues
  });
  const navigate = useNavigate();
  const inputWidth = { base: "100%", md: "500px" };
  return (
    <Box ml={{ base: 0, md: 40 }} mt={30}>
      <Heading as="h2" size="md">
        About the organization
      </Heading>
      <Box m={4}>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <Flex direction="column" gap={4} width="100%">
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Organization Name:
              </Text>
              <Input placeholder="Name" {...register("name")} bg="white" width={inputWidth} />
            </Flex>
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Email Domain(s):
              </Text>
              <Input
                placeholder="avodah.dev, wwa.co, google.com"
                {...register("domains")}
                bg="white"
                width={inputWidth}
              />
            </Flex>

            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Service Start Date:
              </Text>
              <Controller
                name="serviceStartDate"
                control={control}
                render={({ field }) => (
                  <AvDatePicker
                    selectedDate={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    placeholderText="Select Start Date..."
                    inputProps={{ size: "md", width: inputWidth }}
                  />
                )}
              />
            </Flex>
            <Flex align="center" gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Service End Date:
              </Text>
              <Controller
                name="serviceEndDate"
                control={control}
                render={({ field }) => (
                  <AvDatePicker
                    selectedDate={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    placeholderText="Select End Date..."
                    inputProps={{ size: "md", width: inputWidth }}
                  />
                )}
              />
            </Flex>
            <Flex align={defaultValues ? "center" : "top"} gap={4} flexWrap={{ base: "wrap", md: "nowrap" }}>
              <Text minW="200px" whiteSpace="nowrap">
                Organization Admin(s):
              </Text>
              {defaultValues ? (
                <Button
                  onClick={() => navigate(`/orgs/${defaultValues.id}/admins`)}
                  minWidth="100px"
                  variant="outline"
                  type="submit"
                >
                  Manage Admins
                </Button>
              ) : (
                <>
                  <Textarea
                    placeholder="janedoe@acme.co, joshdoe@acme.co"
                    resize="vertical"
                    {...register("admins")}
                    bg="white"
                    width={inputWidth}
                    borderRadius={3}
                    height="40px"
                  />
                </>
              )}
            </Flex>
            <Flex
              mt={4}
              gap={2}
              justifyContent={{ base: "center", md: "flex-end" }}
              w="100%"
              flexWrap={{ base: "wrap", md: "nowrap" }}
            >
              <Button onClick={onCancel} variant="ghost" color={ThemeColors.brand.primary} minWidth="100px">
                Cancel
              </Button>
              <Button type="submit" variant="outline" w={{ base: "90vw", md: "auto" }}>
                Save
              </Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};
