import { useState } from "react";

import { Flex } from "@chakra-ui/react";

import { AvDatePicker } from "~/components/AvDatePicker";
const AnotherPage = () => {
  const [date, setDate] = useState<Date | null>(null);
  return (
    <Flex align={["top", "top"]}>
      <AvDatePicker selectedDate={date} onChange={(e) => setDate(e)} />
    </Flex>
  );
};

export default AnotherPage;
