import { UserInfo } from "@shared/domain.models";
import { create } from "zustand";

export interface Conversation {
  conversationid: number;
}

export interface AppState {
  userInfo?: UserInfo;
  isAuthorized: boolean;
  filter: string;
  allConversations: Conversation[];
  filteredConversations: Conversation[];
  setUserInfo: (userInfo: UserInfo | undefined) => void;
  setIsAuthorized: (isAuthorized: boolean) => void;
  setFilter: (filter: string) => void;
  setAllConversations: (all: Conversation[]) => void;
  setFilteredConversations: (filtered: Conversation[]) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  isAuthorized: true,
  filter: "",
  allConversations: [],
  filteredConversations: [],
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
  setIsAuthorized: (isAuthorized) => set(() => ({ isAuthorized })),
  setAllConversations: (all) => set(() => ({ allConversations: all })),
  setFilteredConversations: (filtered) => set(() => ({ filteredConversations: filtered })),
  setFilter: (filter) => set(() => ({ filter }))
}));
