import { IconButton } from "@chakra-ui/icons";
import {
  Box,
  ListItem,
  Text,
  Icon,
  HStack,
  Grid,
  Popover,
  Portal,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button
} from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { BsThreeDots } from "react-icons/bs";
import { CgArrowTopRight } from "react-icons/cg";
import { useNavigate, Link } from "react-router-dom";

import { ThemeColors } from "~/styles/theme";

export const OrgItem = ({ org }: { org: Organization }) => {
  const navigate = useNavigate();

  return (
    <ListItem key={org.id} p={4} border={{ base: "1px solid gray", md: "none" }}>
      <Grid
        templateColumns={{
          base: "repeat(3, 1fr)",
          md: "repeat(5, 1fr) 40px"
        }}
        gap={4}
        alignItems="center"
        borderRadius="3"
      >
        <Link to={`/dashboard/${org.id}`}>
          <HStack gap={0}>
            <Text fontWeight="bold">{org.name}</Text>
            <Icon>
              <CgArrowTopRight size="2em" />
            </Icon>
          </HStack>
        </Link>

        <Text>{org.domains.join(", ")}</Text>
        {org.isActive ? <Text>Active</Text> : <Text color="red.500">Inactive</Text>}
        <Text>{org.serviceStartDate ? new Date(org.serviceStartDate).toLocaleDateString() : "N/A"}</Text>
        <Text>{org.serviceEndDate ? new Date(org.serviceEndDate).toLocaleDateString() : "N/A"}</Text>
        <Popover>
          <PopoverTrigger>
            <IconButton aria-label="Options" margin="auto" icon={<BsThreeDots />} variant="plain" />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Box>
                  <Button
                    onClick={() => navigate(`/orgs/${org.id}`)}
                    colorScheme={ThemeColors.brand.primary}
                    size="sm"
                    variant="ghost"
                  >
                    Manage Organization
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => navigate(`/orgs/${org.id}/admins`)}
                    colorScheme={ThemeColors.brand.primary}
                    variant="ghost"
                    size="sm"
                  >
                    Manage Admins
                  </Button>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Grid>
    </ListItem>
  );
};
