import { Organization } from "@shared/domain.models";
import ExcelJs from "exceljs";

export async function downloadOrgExcel(orgs: Organization[], filename: string) {
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet("Organizations");
  worksheet.columns = [
    { header: "ID", key: "id", width: 10 },
    { header: "Name", key: "name", width: 32 },
    { header: "Domains", key: "domains", width: 32 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Service Start Date", key: "serviceStartDate", width: 20 },
    { header: "Service End Date", key: "serviceEndDate", width: 20 },
    { header: "Admins", key: "Admins", width: 20 }
  ];
  orgs.forEach((org) => {
    worksheet.addRow({
      id: org.id,
      name: org.name,
      domains: org.domains.join(", "),
      createdAt: new Date(org.createdAt).toISOString().split("T")[0],
      serviceStartDate: org.serviceStartDate ? new Date(org.serviceStartDate).toISOString().split("T")[0] : "",
      serviceEndDate: org.serviceEndDate ? new Date(org.serviceEndDate).toISOString().split("T")[0] : "",
      Admins: org.admins.join(", ")
    });
  });
  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(link.href);
}
