import { Organization } from "@shared/domain.models";
import { useQuery, useMutation, UseMutationResult, UseQueryResult } from "@tanstack/react-query";

import { queryClient } from "~/App";

export function useDbOrgs() {
  const useAllOrgs = (): UseQueryResult<Organization[]> =>
    useQuery<Organization[]>({
      queryKey: ["orgs"],
      queryFn: async () => {
        const response = await fetch("/api/orgs");
        if (!response.ok) {
          throw new Error("Failed to fetch organizations");
        }
        return response.json();
      }
    });

  const useGetOrgById = (orgId: number): UseQueryResult<Organization> =>
    useQuery<Organization>({
      queryKey: ["org", orgId],
      queryFn: async () => {
        const response = await fetch(`/api/orgs/${orgId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization");
        }
        return response.json();
      }
    });

  const useCreateOrg = (): UseMutationResult<Organization, Error, Organization> =>
    useMutation<Organization, Error, Organization>({
      mutationFn: async (newOrg: Organization) => {
        const response = await fetch("/api/orgs", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(newOrg)
        });
        if (!response.ok) {
          throw new Error("Failed to create organization");
        }
        return response.json();
      }
    });

  const useUpdateOrg = (): UseMutationResult<Organization, Error, { orgId: number; updatedOrg: Organization }> =>
    useMutation<Organization, Error, { orgId: number; updatedOrg: Organization }>({
      mutationFn: async ({ orgId, updatedOrg }) => {
        const response = await fetch(`/api/orgs/${orgId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(updatedOrg)
        });
        if (!response.ok) {
          throw new Error("Failed to update organization");
        }
        return response.json();
      },
      onSuccess: (_, { orgId }) => {
        // Invalidate and refetch queries related to orgs
        queryClient.invalidateQueries({ queryKey: ["orgs"] });
        queryClient.invalidateQueries({ queryKey: ["org", orgId] });
      }
    });

  const useDeleteOrg = (): UseMutationResult<void, Error, number> =>
    useMutation<void, Error, number>({
      mutationFn: async (orgId: number) => {
        const response = await fetch(`/api/orgs/${orgId}`, {
          method: "DELETE"
        });
        if (!response.ok) {
          throw new Error("Failed to delete organization");
        }
      }
    });

  return {
    useAllOrgs,
    useGetOrgById,
    useCreateOrg,
    useUpdateOrg,
    useDeleteOrg
  };
}
