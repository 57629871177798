export enum UserRole {
  SuperAdmin = "Super Admin",
  Admin = "Admin",
  NoAccess = "No Access"
}

export interface UserInfo {
  id: number;
  authId: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  isSuperAdmin: boolean;
  isOrgAdmin?: boolean;
  orgId?: number;
  orgName?: string;
  createdAt: Date;
  isActive?: boolean;
}

export interface Organization {
  id?: number;
  name: string;
  domains: string[];
  serviceStartDate?: Date;
  serviceEndDate?: Date;
  isActive: boolean;
  createdAt: Date;
  admins: UserInfo[];
}
