import { useState } from "react";

import { Box, Button, Flex, Grid, Heading, HStack, List, ListItem, Spinner, Text } from "@chakra-ui/react";
import { UserInfo } from "@shared/domain.models";
import { useQuery } from "@tanstack/react-query";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";

import { AdminItem } from "~/components/AdminItem";
import { ButtonMenu } from "~/components/ButtonMenu";
import { useDbUsers } from "~/hooks/useDbUsers";
import { downloadAdminExcel } from "~/utils/downloadAdminExcel";

const SuperAdminListPage = () => {
  const { useGetAllUsers } = useDbUsers();
  const { data: users, isLoading, error } = useGetAllUsers();

  const [statusFilter, setStatusFilter] = useState<string>("All");
  const statusFilterOptions = ["All", "Active", "Inactive"];
  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading Users...</Text>
      </Flex>
    );
  }

  if (error instanceof Error) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text color="red.500">Error: {error.message}</Text>
      </Flex>
    );
  }

  const filteredUsers = users?.filter((user) => {
    //TODO add filtering for super admins
    if (statusFilter === "All") return true;
    return statusFilter === "Active" ? user.isActive : !user.isActive;
  });
  return (
    <Flex direction="column" align="start" gap={4}>
      <HStack
        justifyContent="space-between"
        mb={4}
        alignItems="center"
        flexWrap={{ base: "wrap", md: "nowrap" }}
        w="100%"
      >
        <Heading size={{ base: "lg", md: "xl" }}>Super Admins</Heading>
        <HStack spacing={2} flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Button
            variant="outline"
            gap={2}
            display="flex"
            alignItems="center"
            onClick={() => {
              /* Add functionality here */
            }}
          >
            <FaPlus />
            Add Super Admin
          </Button>
          <Button
            variant="outline"
            gap={2}
            display="flex"
            alignItems="center"
            onClick={() => users && downloadAdminExcel(users, "SuperAdmins.xlsx")}
            leftIcon={<BsArrowDownSquareFill />}
          >
            Export
          </Button>
        </HStack>
      </HStack>
      <ButtonMenu value={statusFilter} setValue={setStatusFilter} options={statusFilterOptions} />
      <Box borderRadius={10} border="1px solid" borderColor="gray.200" p={4} w="100%">
        <List width="100%">
          <ListItem key="header" p={4}>
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr) 40px"
              }}
              gap={4}
              alignItems="center"
            >
              <Text color="gray.500">Name</Text>
              <Text color="gray.500">Email</Text>
              <Text color="gray.500">Status</Text>
              <Text color="gray.500">Start Date</Text>
            </Grid>
          </ListItem>
          {filteredUsers?.map((user) => <AdminItem admin={user} key={user.id} />)}
          {(filteredUsers?.length === 0 || !filteredUsers) && <Text>No super admins found.</Text>}
        </List>
      </Box>
    </Flex>
  );
};

export default SuperAdminListPage;
