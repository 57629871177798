import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { ThemeColors } from "~/styles/theme";

export const ButtonMenu = ({ value, setValue, options, size = "md" }: MenuItemProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        border="1px solid"
        size={size}
        color={ThemeColors.brand.primary}
        display="inline-flex"
        minWidth="fit-content"
        alignItems="center"
        gap={2}
        justifyContent="space-between"
        whiteSpace="nowrap"
      >
        {value}
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem onClick={() => setValue(option)} key={option}>
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

interface MenuItemProps {
  value: string;
  setValue: (value: string) => void;
  options: string[];
  size?: "sm" | "md" | "lg";
}
